import React from 'react';
import Layout from '../../../components/layout';
import RegistrationFrom from '../../../components/registration-form/registration-from';
import SEO from '../../../components/seo';

export default function RegisterJAFZA(props) {
  return (
    <Layout>
      <SEO lang='en' title='#GMIS2022 - JAFZA' />
      <RegistrationFrom source='JAFZA' page='jafza' imageName='jafza-expo' imageAlt='jafza-expo' />
    </Layout>
  );
}
